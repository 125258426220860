import React from 'react';
import Layout from 'layout';
import Link from 'components/ui/link';
import Button from 'components/ui/button';

const ContentSpecialist = () => (
  <Layout
    metaTitle="Job - Content Specialist"
    canonical="/jobs/content-specialist/"
    metaDescription="We are looking for Content Specialist - join us if you are looking for new challenges!"
  >
    <section>
      <div className="container">
        <div className="row">
          <div className="col-lg-8 text-left p-4 m-auto">
            <h1 className="mb-5 text-center">Content Specialist</h1>
            <p className="mb-3">
              We’re looking for a talented <strong>Content Specialist</strong> to help us distribute
              and create content for our blog, landing pages, help center, and social media.
            </p>
            <p>
              <Link href="/" target="_blank" rel="noopener noreferrer">
                LiveSession
              </Link>{' '}
              is a growing B2B SaaS company that helps companies understand how customers use their
              websites and apps. Join us and help us create the best qualitative analytics software
              on the market!
            </p>
            <p>
              This is a permanent, <strong>half</strong>, or <strong>full-time</strong> remote
              position.{' '}
            </p>
            <p className="mt-4">
              <strong>Job description</strong>:{' '}
            </p>
            <ul>
              <li>
                create content strategy for our{' '}
                <Link href="/blog/" target="_blank" rel="noopener noreferrer">
                  blog
                </Link>{' '}
                and landing pages, in collaboration with marketing and UX professionals
              </li>
              <li>
                write in-depth blog posts about <strong>UX</strong>, <strong>CRO</strong>, and other
                topics related to our product
              </li>
              <li>write onboarding and marketing emails</li>
              <li>write feature news and help center articles</li>
              <li>write and publish social media content, related to content on our website</li>
            </ul>
            <p className="mt-4">
              <strong>Requirements</strong>:{' '}
            </p>
            <ul>
              <li>fluency in English, particularly written (minimum C1)</li>
              <li>experience in working on a similar position (SaaS experience is a big plus!)</li>
              <li>basic knowledge of SEO and HTML</li>
              <li>passionate about technologies, UX, and conversion optimization</li>
              <li>
                experience distributing and publishing content on the blog, Facebook, LinkedIn, and
                Twitter
              </li>
              <li>
                experience using Google Suite (including Gmail, Google Docs, Google Sheets, etc.)
              </li>
              <li>the ability and desire to work in a fast-growing startup environment</li>
            </ul>
            <p className="mt-4">
              <strong>Benefits</strong>:{' '}
            </p>
            <ul>
              <li>salary tailored to your experience, skills, and performance</li>
              <li>fully remote with flexible working hours</li>
              <li>startup atmosphere</li>
            </ul>
            <p className="mt-4">
              Send us your CV, writing samples, and a few words about yourself:{' '}
              <Link href="mailto:jobs@livesession.io">jobs@livesession.io</Link>
            </p>
            <p
              style={{
                margin: '3rem',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Button href="mailto:jobs@livesession.io">Apply</Button>
            </p>
            <p className="mt-5" style={{ fontSize: '0.8rem', color: 'grey' }}>
              By sending a recruitment application to LiveSession Sp. z o.o. with headquarters in
              Wroclaw, at gen. Wladyslawa Sikorskiego 3/2, 56-659, Wroclaw, entered in the register
              of entrepreneurs of the National Court Register under number 0000753134, Tax ID:
              PL8971860301, REGON: 381580380, you agree to the processing of personal data contained
              in the recruitment application by the Company in order to recruit for the position
              indicated in the announcement.
            </p>
          </div>
        </div>
      </div>
    </section>
  </Layout>
);

export default ContentSpecialist;
